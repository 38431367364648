import { RandomizedAnimation } from 'components/Animation';
import Image from 'components/Image/Image';
import Head from 'next/head';

const Snowflake = () => {
  return <Image src="/images/advent/SNOWFLAKE.svg" alt="snowflake" width={50} height={50} />;
};

const SnowFlakes = () => (
  <>
    <Head>
      <link key={`preload-snowflake`} rel="preload" href={`/images/advent/SNOWFLAKE.svg`} type="image/svg+xml" />
    </Head>
    <RandomizedAnimation id="snowflake">
      <Snowflake />
    </RandomizedAnimation>
  </>
);

export default SnowFlakes;
