import { CMSPageProps } from 'containers/CMSContainer';
import { ContentfulPageProps } from 'containers/ContentfulContainer';
import { Category, CategoryBanner } from './Category';
import { FooterSocialLink } from './Footer';
import { Image, StaticImage } from './Image';
import { PaymentMethod } from './Payment';
import { UspItem } from './Product';
import { RobotTag } from './SEO';
import { LocalizedUrls } from './Url';

export type CMSMasonryContentBlock = {
  button: Button;
  image: StaticImage;
  template: string;
  text: string | string[];
  title: string;
};

type Button = {
  isExternal?: boolean;
  position: 'RIGHT_BOTTOM' | 'LEFT_BOTTOM' | 'RIGHT_TOP' | 'LEFT_TOP';
  text: string;
  url: string;
};

export type CMSContainerProps = ContentfulPageProps | CMSPageProps;

export const isContentfulPage = (props: CMSContainerProps): props is ContentfulPageProps =>
  props.pageType === 'contentfulPage';

export type CMSPageData = {
  buttons?: Button[];
  criteoCategory?: string;
  description?: string;
  enableCMSSponsoredProducts?: boolean;
  enableMessenger?: boolean;
  facetLimit?: number;
  fallbackText?: string;
  initialQuery?: string;
  metaDescription?: string;
  plpContentBanners?: CategoryBanner[];
  qualifier?: string;
  robotTag?: RobotTag;
  secondDescription?: string;
  slots?: CMSPageSlot[];
  sponsoredProductsFilter?: string;
  superCategory?: Category;
  title?: string;
  titleTag?: string;
  type: 'CMSEmptyContentPageModel' | 'CMSSearchPageModel' | 'ContentPageModel';
};

export type CMSSearchPage = Omit<CMSPageData, 'type'> & {
  enableCriteoFlagship?: boolean;
  enableCriteoSponsoredProducts?: boolean;
  type: 'CMSSearchPageModel';
};

export const isCMSSearchPage = (page: CMSPageData): page is CMSSearchPage => page.type === 'CMSSearchPageModel';

export type CMSEmptyContentPage = Omit<CMSPageData, 'type'> & {
  type: 'CMSEmptyContentPageModel';
};

export const isCMSEmptyContentPage = (page: CMSPageData): page is CMSEmptyContentPage =>
  page.type === 'CMSEmptyContentPageModel';

export type CMSPageSlot = {
  components?: CMSComponent[];
  position?: string;
  slotId?: string;
  template?: string;
};

export type CMSComponentChildObject = {
  key: string;
  value: { type: string; value?: string };
};

export type CMSComponentOtherProperties = CMSComponentChildObject[];

export type CMSFooterComponent = {
  linkComponents: FooterSocialLink[];
  paymentModes: PaymentMethod[];
  title: string;
};

export type CMSComponent = CMSUspComponent & {
  catalogVersion?: string;
  enableCmsScriptEval?: boolean;
  linkComponents: CMSHeaderLinkComponentWsDTO[];
  modifiedtime?: string;
  name?: string;
  otherProperties?: CMSComponentOtherProperties;
  title?: string;
  type?: string;
  typeCode: string;
  uid: string;
};

export type CMSParagraphComponentContent = {
  entry?: { key: string; value: string }[];
};

export type CMSHeaderLinkComponentWsDTO = {
  externalUrl?: string;
  localizedName?: string;
  type: 'cmsHeaderLinkComponentWsDTO';
  url?: string;
};

export type CMSUspComponent = {
  type: 'cmsuspComponentWsDTO';
  uspConfiguration?: { uspItems?: UspItem[] };
};

export type CMSContentPageModel = CMSPageData & {
  localizedFullUrls: LocalizedUrls;
  localizedUrls: LocalizedUrls;
  qualifier: string;
  slots: CMSPageSlot[];
  type: 'ContentPageModel';
};

export type CMSContentBlock = {
  button?: { text?: string; url?: string };
  text?: string[];
  title?: string;
};

export type CMSLeaderBoardsComponent = {
  leaderboards?: CMSLeaderBoard[];
};

export type CMSLeaderBoard = {
  backgroundImage?: StaticImage;
  backgroundImageMultiBanner?: StaticImage;
  linkUrl?: string;
  mobileBackgroundImage?: StaticImage;
  mobileBackgroundImageMultiBanner?: StaticImage;
  openInNewTab?: boolean;
  title?: string;
  uid: string;
};

export type CMSLink = {
  externalUrl?: string;
  localizedName?: string;
  type: string;
  url?: string;
};

export type CMSLinkSlot = {
  components?: CMSLink[];
  position?: string;
};
export type CMSButton = { isExternal?: boolean; text?: string; url?: string };

export type CMSBannerCarouselComponent = {
  bannerTitle: string;
  button: CMSButton;
  mediumImage: string;
  mobileImage: string;
  showButton: boolean;
  subtitle: string;
  title: string;
  wideImage: string;
};

export type CMSBannerCarouselComponentProps = {
  components: CMSBannerCarouselComponent[];
};

export type CMSOrderedSlot = {
  components: (CMSUspComponent | CMSHeaderLinkComponentWsDTO)[];
  position: string;
};

export type CMSHeaderPage = {
  orderedSlots: CMSOrderedSlot[];
};

export type CMSCategoryTile = {
  image?: Image;
  link?: string;
  name?: string;
};
