import { useMemo } from 'react';
import { getRandomAnimationDelay, getRandomAnimationDuration, getRandomOffsetPercentage } from 'utils/animation';
import { StyledAnimatedCircle } from './Confetti.styled';
import { COLORMAPPING, getRandomColor } from './ConfettiUtils';

const AMOUNT_OF_CIRCLES = 8;
interface CirclesProps {
  size: number;
}

const Circles = ({ size }: CirclesProps) => {
  const randomCircles = useMemo(
    () =>
      Array.from(Array(AMOUNT_OF_CIRCLES).keys()).map((index) => (
        <StyledAnimatedCircle
          key={index}
          className={`circle circle-${index + 1}`}
          animationDelay={getRandomAnimationDelay()}
          animationDuration={getRandomAnimationDuration()}
          offset={getRandomOffsetPercentage()}
          size={size}
          color={COLORMAPPING[getRandomColor(index)]}
        />
      )),
    [size]
  );
  return <>{randomCircles}</>;
};

export default Circles;
