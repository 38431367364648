import styled from 'styled-components';
import { color } from 'theme';

export const Heart = styled.div<{ size: number }>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  position: relative;
  transform: rotate(-45deg);
  background-color: ${color('love-300')};
  margin: auto;

  &:before,
  &:after {
    content: '';
    width: ${({ size }) => `${size}px`};
    height: ${({ size }) => `${size}px`};
    background-color: ${color('love-300')};
    border-radius: 50%;
    position: absolute;
  }

  &:before {
    top: ${({ size }) => `-${size / 2}px`};
    left: 0;
  }

  &:after {
    top: 0;
    left: ${({ size }) => `-${size / 2}px`};
  }
`;
