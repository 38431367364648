import { CMSEmptyContentPage } from 'types/CMS';
import Container from 'ui/Container/Container';
import { Text } from 'ui/Text/Text';
import CMSSlot from '../../CMSSlot/CMSSlot';

type CMSEmptyPageProps = {
  cmsPage: CMSEmptyContentPage;
};

const CMSEmptyPage = ({ cmsPage }: CMSEmptyPageProps) => (
  <section className="max-w-screen-xxl">
    {cmsPage?.title && (
      <Container>
        <Text type="h1">{cmsPage.title}</Text>
      </Container>
    )}
    {cmsPage?.slots?.map((slot, index) => <CMSSlot key={slot.slotId} cmsPage={cmsPage} position={index + 1} />)}
  </section>
);

export default CMSEmptyPage;
