import InteractiveVisual from '../../InteractiveVisual/InteractiveVisual';
import Hearts from './Hearts';

const ValentineHeartsVisual = () => (
  <InteractiveVisual>
    <Hearts />
  </InteractiveVisual>
);

export default ValentineHeartsVisual;
