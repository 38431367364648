import { ReactNode } from 'react';
import AnimatedItem from '../AnimatedItem/AnimatedItem';

type Props = {
  amount?: number;
  children: ReactNode;
  id: string;
};

const RandomizedAnimation = ({ amount = 16, children, id }: Props) => (
  <>
    {Array.from(Array(amount).keys()).map((index) => (
      <AnimatedItem offset={(index * 100) / amount} key={`${id}-${index}`}>
        {children}
      </AnimatedItem>
    ))}
  </>
);

export default RandomizedAnimation;
