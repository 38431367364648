import Loader from 'components/Layout/Loader/Loader';
import PlpLayout from 'components/Layout/Plp/PlpLayout';
import Link from 'components/Link/Link';
import SearchNoResults from 'components/Search/SearchNoResults/SearchNoResults';
import { PIANO_PAGE_NAMES, PIANO_PAGE_TYPES } from 'constants/analytics';
import { isWebview } from 'constants/environment';
import { useRealtimeInfo } from 'features/product/queries';
import { useSearchProducts } from 'features/search/queries';
import { useCriteoRetailMediaForCategory } from 'hooks/criteo/useCriteoRetailMediaForCategory';
import { useSponsoredProducts } from 'hooks/criteo/useSponsoredProducts';
import useAuthResolver from 'hooks/useAuthResolver';
import useRouter from 'hooks/useRouter';
import useTrackingEvents from 'hooks/useTrackingEvents';
import { pathnames } from 'i18n/pathnames';
import dynamic from 'next/dynamic';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { CMSSearchPage as CMSSearchPageType } from 'types/CMS';
import { getBreadcrumbFromCMSPage } from 'utils/breadcrumbUtil';
import { getCategoryBreadcrumb, getSearchResultsWithSponsoredProducts } from 'utils/criteoUtil';
import { getValuable } from 'utils/objectUtil';
import { scrollTo } from 'utils/scrollUtil';
import { getProductCodesFromSearchResult } from 'utils/searchUtil';
import { decodeUrlParam } from 'utils/urlUtil';

const AddToCartSheet = dynamic(() => import('components/Sheets/AddToCartSheet/AddToCartSheet'));

interface CMSSearchPageProps {
  cmsPage: CMSSearchPageType;
}

const CMSSearchPage = ({ cmsPage }: CMSSearchPageProps) => {
  const { formatMessage } = useIntl();
  const router = useRouter();

  const { onPageView, onPLPView } = useTrackingEvents();
  const { authResolved, user } = useAuthResolver();

  const breadcrumb = isWebview ? undefined : getBreadcrumbFromCMSPage(cmsPage);

  const {
    criteoCategory,
    enableCMSSponsoredProducts,
    enableCriteoFlagship,
    enableCriteoSponsoredProducts,
    initialQuery = '',
    qualifier = '',
    sponsoredProductsFilter,
    superCategory,
  } = cmsPage;

  const { data: searchResults, isError, isFetching, isSuccess } = useSearchProducts(initialQuery, qualifier);

  const {
    facets = [],
    freeTextSearch = '',
    pagination,
    products: searchProducts = [],
    sorts = [],
  } = searchResults ?? {};
  const { currentPage = 0 } = pagination ?? {};

  const { flagshipPlacements, sponsoredProductsPlacements } = useCriteoRetailMediaForCategory(
    {
      category: criteoCategory ?? (superCategory && getCategoryBreadcrumb(superCategory)) ?? '',
      searchResults: searchResults,
      signings: sponsoredProductsFilter,
    },
    {
      enabled: enableCMSSponsoredProducts,
      flagship: {
        enabled: enableCriteoFlagship,
      },
      sponsoredProducts: {
        enabled: enableCriteoSponsoredProducts,
      },
    },
  );
  const flagship = flagshipPlacements?.[0];
  const { sponsoredProducts } = useSponsoredProducts(sponsoredProductsPlacements);

  const gridProducts = useMemo(
    () => getSearchResultsWithSponsoredProducts(searchProducts, sponsoredProducts),
    [sponsoredProducts, searchProducts],
  );

  const results = useMemo(() => getProductCodesFromSearchResult({ products: gridProducts }), [searchResults]);
  const { data: realtimeInfoProducts } = useRealtimeInfo(results);

  const freeTextSearchValue = useMemo(() => decodeUrlParam(freeTextSearch), [searchResults]);

  const { asPath = '', locale, query } = router;
  const { pageSize, sort } = query ?? {};

  const createPageLink = (page?: number, label?: string | number, className = '') => (
    <Link
      shallow
      url={{
        pathname: `${cmsPage?.qualifier}`,
        query: getValuable({
          currentPage: page,
          pageSize,
          q: searchResults?.currentQuery?.query?.value,
          sort,
        }),
      }}
    >
      <div
        className={className}
        onClick={() => scrollTo(undefined, 'search-results-header')}
        role="button"
        tabIndex={0}
      >
        {label}
      </div>
    </Link>
  );

  useEffect(() => {
    if (searchResults && authResolved) {
      const pageNumber = currentPage + 1;
      const qualifier = cmsPage.qualifier ?? '';

      const categoryFromCMSPageData = {
        code: cmsPage.title ?? '',
        name: cmsPage.title,
        superCategory: cmsPage.superCategory,
      };

      onPageView({
        analyticsPageName: PIANO_PAGE_NAMES.PLP,
        analyticsPageType: PIANO_PAGE_TYPES.PLP,
        locale,
        path: asPath,
        pathname: pathnames.CMS_SEARCH_PAGE,
        slug: qualifier,
        user: user ?? undefined,
        ...(cmsPage?.superCategory && {
          category: categoryFromCMSPageData,
        }),
      });

      onPLPView({ category: categoryFromCMSPageData, pageNumber, products: searchResults?.products });
    }
  }, [searchResults, authResolved, locale]);

  return (
    <>
      <AddToCartSheet />
      <Loader show={isFetching && !!searchProducts?.length} />
      {(isSuccess || isError) && !searchProducts.length ? (
        <SearchNoResults
          buttons={[
            {
              label: formatMessage({
                defaultMessage: ' ',
                id: 'search_back_button',
              }),
              onClick: () => router?.back?.(),
              outlined: true,
            },
          ]}
          hasChecks
          title={formatMessage(
            { id: 'search_no_results_text' },
            {
              query: freeTextSearchValue && freeTextSearchValue !== 'undefined' ? freeTextSearchValue : '',
            },
          )}
        />
      ) : (
        <PlpLayout
          breadcrumb={breadcrumb}
          flagship={flagship}
          createPageLink={createPageLink}
          products={gridProducts}
          facets={facets}
          pagination={pagination}
          cmsSearchPage={cmsPage}
          sorts={sorts}
          realtimeInfoProducts={realtimeInfoProducts?.products ?? []}
        />
      )}
    </>
  );
};

export default CMSSearchPage;
