export const COLORS = ['Blue', 'Green', 'Pink', 'Yellow'] as const;
export const COLORMAPPING = {
  Blue: '#196a8f',
  Green: '#00a29e',
  Pink: '#e8468b',
  Yellow: '#ffcd12',
};

export const getRandomColor = (index: number): typeof COLORS[number] => {
  return COLORS[(index + 1) % COLORS.length];
};
