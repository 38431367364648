import { RandomizedAnimation } from 'components/Animation';
import { Heart } from './Heart.styled';

const Hearts = () => (
  <>
    <RandomizedAnimation id="snowflake">
      <Heart size={25} />
    </RandomizedAnimation>
  </>
);

export default Hearts;
