import styled from 'styled-components';

export interface StyledAnimatedItemProps {
  animationDelay: number;
  animationDuration: number;
  offset: number;
  size: number;
}

export const StyledAnimatedItem = styled.div<StyledAnimatedItemProps>`
  left: calc(${({ offset }) => offset}% - ${({ size }) => size}px);
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  animation-duration: ${({ animationDuration }) => animationDuration}ms;
  animation-delay: ${({ animationDelay }) => animationDelay}ms;
`;
