import InteractiveVisual from '../../InteractiveVisual/InteractiveVisual';
import Circles from './ConfettiCircles';
import Logos from './ConfettiLogos';

const BirthdayConfettiVisual = () => (
  <InteractiveVisual>
    <Logos />
    <Circles key="small" size={20} />
    <Circles key="medium" size={25} />
    <Circles key="large" size={30} />
  </InteractiveVisual>
);

export default BirthdayConfettiVisual;
