export const getRandomOffsetPercentage = () => {
  return Math.random() * 100;
};

export const getRandomAnimationDelay = () => {
  return 1000 + Math.random() * 3000 - Math.random() * 3000;
};

export const getRandomAnimationDuration = () => {
  return 5000 + Math.random() * 2000 - Math.random() * 2000;
};
