import styled from 'styled-components';

export const StyledInteractiveVisual = styled.div`
  position: fixed;
  top: 0;
  padding: 0;
  user-select: none;
  pointer-events: none;
  z-index: 99999;

  @keyframes fall-and-shake {
    0% {
      transform: translateY(-10vh) rotate(-45deg);
    }
    50% {
      transform: translateY(50vh) rotate(45deg);
    }
    100% {
      transform: translateY(110vh) rotate(-45deg);
    }
  }

  & > * {
    position: fixed;
    top: -10vh;
    pointer-events: none;
    animation-name: fall-and-shake;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-play-state: paused;
    display: none;
  }

  &.animate > * {
    display: unset;
    animation-play-state: running;
  }
`;
