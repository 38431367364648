import { memo } from 'react';
import isEqual from 'react-fast-compare';
import { IContentPromo, IPageContent } from 'types/ContentfulTypes';
import { cn } from 'utils/cn';
import { getContentfulPageDate } from '../../../utils/contentfulUtil';
import AnalyticsWrapper from '../../Analytics/AnalyticsWrapper/AnalyticsWrapper';
import BreadCrumb from '../../Breadcrumb/Breadcrumb';
import ContentfulBanner from '../ContentfulFields/ContentfulBanner/ContentfulBanner';
import ContentfulInteractiveVisual from '../ContentfulFields/ContentfulInteractiveVisual/ContentfulInteractiveVisual';
import { CONTENTFUL_TYPES } from '../ContentfulFields/types';
import ContentfulLayout from '../ContentfulLayout/ContentfulLayout';
import ContentfulModelMapper from '../ContentfulModelMapper/ContentfulModelMapper';
import {
  BannerWrapper,
  CountdownDesktop,
  CountdownMobile,
  StyledSocialSharing,
  StyledTimestamp,
} from './ContentfulRenderer.styled';

interface ContentfulRendererProps {
  contentfulPage: IPageContent;
}

const ContentfulRenderer = ({ contentfulPage }: ContentfulRendererProps) => {
  const pageFields = contentfulPage?.fields;

  if (!pageFields) {
    return null;
  }

  const { banner, breadcrumb, interactiveVisual, socialSharing, template } = pageFields;

  if (!template) {
    return null;
  }

  const { contentType } = template.sys;

  const isDefaultTemplate = contentType?.sys?.id === CONTENTFUL_TYPES.CONTENT_DEFAULT;
  const isPromoTemplate = contentType?.sys?.id === CONTENTFUL_TYPES.CONTENT_PROMO;

  const countdown = isPromoTemplate ? (template as IContentPromo)?.fields?.countdown : undefined;

  const hasCTABar = !!(breadcrumb || socialSharing);

  return (
    <AnalyticsWrapper>
      <ContentfulLayout>
        {!!interactiveVisual && <ContentfulInteractiveVisual interactiveVisual={interactiveVisual} />}
        {banner && (
          <BannerWrapper>
            <ContentfulBanner
              className="contentful-template-banner"
              content={banner}
              priority
              showGradient={isDefaultTemplate}
              justifyContentBannerText={isPromoTemplate ? 'center' : 'flex-end'}
              paddingBottomBannerText={isPromoTemplate ? 0 : 200}
            />
            {countdown && <CountdownDesktop content={countdown} />}
          </BannerWrapper>
        )}

        {countdown && <CountdownMobile content={countdown} />}
        {hasCTABar && (
          <div
            className={cn(
              'flex items-center justify-between',
              '[&:first-child]:mt-2 [&:last-child]:mb-2 [&:not(:first-child)]:-mt-7 [&:not(:last-child)]:-mb-7', //To offset the parent's gap and have gap be 24px or 1.5rem
              !socialSharing ? 'hidden md:flex' : '',
            )}
          >
            {breadcrumb && <BreadCrumb className="hidden md:flex" activeLabel={breadcrumb} />}
            {socialSharing && <StyledSocialSharing />}
          </div>
        )}
        {template && <ContentfulModelMapper content={template} />}
        <StyledTimestamp date={getContentfulPageDate(contentfulPage)} labelId="cms_updated_on_copy" />
      </ContentfulLayout>
    </AnalyticsWrapper>
  );
};

export default memo(ContentfulRenderer, isEqual);
