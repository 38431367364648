import PageLayout from 'components/PageLayout';
import CMSContainer from 'containers/CMSContainer';
import ContentfulContainer from 'containers/ContentfulContainer';
import { fetchCmsData, getStaticCmsPaths } from 'data/cmsData';
import { pathnames } from 'i18n/pathnames';
import { GetStaticPropsContext } from 'next';
import { ReactNode } from 'react';
import wrapper from 'redux/store';
import { CMSContainerProps, isContentfulPage } from 'types/CMS';

export const getStaticPaths = getStaticCmsPaths;

export const getStaticProps = wrapper.getStaticProps(async (context: GetStaticPropsContext) =>
  fetchCmsData(context, pathnames.CMSPAGE),
);

const CmsPage = (props: CMSContainerProps) => {
  if (isContentfulPage(props)) {
    return <ContentfulContainer {...props} />;
  }
  return <CMSContainer {...props} />;
};

export default CmsPage;

CmsPage.getLayout = function getLayout(page: ReactNode) {
  return <PageLayout>{page}</PageLayout>;
};
