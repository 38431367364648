import { useEffect, useState } from 'react';
import { useInteractiveVisualOccurred, useUIActions } from 'store/ui';
import { StyledInteractiveVisual } from './InteractiveVisual.styled';

interface InteractiveVisualProps {
  children: React.ReactNode | React.ReactNode[];
}

const InteractiveVisual = ({ children }: InteractiveVisualProps) => {
  const hasOccurred = useInteractiveVisualOccurred();
  const { setInteractiveVisualOccurred } = useUIActions();
  const [canAnimate, setCanAnimate] = useState(false);

  const handleAnimationStart = () => {
    setCanAnimate(true);
    setInteractiveVisualOccurred(true);
  };

  useEffect(() => {
    if (!hasOccurred) {
      handleAnimationStart();
    }
  }, []);

  return <StyledInteractiveVisual className={canAnimate ? 'animate' : undefined}>{children}</StyledInteractiveVisual>;
};
export default InteractiveVisual;
