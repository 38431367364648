import { AnimatedItem } from 'components/Animation';
import { ComponentProps } from 'react';
import styled from 'styled-components';

interface StyledAnimatedCircleProps extends ComponentProps<typeof AnimatedItem> {
  color: string;
}

export const StyledAnimatedCircle = styled(AnimatedItem)<StyledAnimatedCircleProps>`
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;
