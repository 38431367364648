import { ReactNode } from 'react';
import { getRandomAnimationDelay, getRandomAnimationDuration } from 'utils/animation';
import { StyledAnimatedItem } from './AnimatedItem.styled';

type Props = {
  animationDelay?: number;
  animationDuration?: number;
  children?: ReactNode;
  className?: string;
  offset?: number;
  size?: number;
};

const AnimatedItem = ({
  animationDelay = getRandomAnimationDelay(),
  animationDuration = getRandomAnimationDuration(),
  children,
  className,
  offset = 0,
  size = 50,
}: Props) => (
  <StyledAnimatedItem
    className={className}
    animationDelay={animationDelay}
    animationDuration={animationDuration}
    offset={offset}
    size={size}
  >
    {children}
  </StyledAnimatedItem>
);

export default AnimatedItem;
