import ArticleSchemeScript from 'components/Seo/SeoSchemeScripts/ArticleSchemeScript/ArticleSchemeScript';
import useAuthResolver from 'hooks/useAuthResolver';
import useRouter from 'hooks/useRouter';
import useTrackingEvents from 'hooks/useTrackingEvents';
import { ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { IPageContent } from 'types/ContentfulTypes';
import ContentfulRenderer from '../components/Contentful/ContentfulRenderer/ContentfulRenderer';
import PageLayout from '../components/PageLayout';
import SEO from '../components/Seo/SEO';
import { pushPageType } from '../utils/analyticsUtil';
import { getContentfulPageDate, handleContentfulRobots } from '../utils/contentfulUtil';
import { getSeoData } from '../utils/seoUtil';
import { getPageNameFormUrl } from '../utils/urlUtil';

export type ContentfulPageProps = {
  contentfulPage: IPageContent;
  pageType: 'contentfulPage';
  slug: string;
};

const ContentfulContainer = ({ contentfulPage, pageType, slug }: ContentfulPageProps) => {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { asPath, locale, pathname } = router;
  const { authResolved, user } = useAuthResolver();
  const { onPageView } = useTrackingEvents();

  const pageName = getPageNameFormUrl(pathname, true);

  const { seoDescription, seoTitle } = getSeoData(contentfulPage?.fields, formatMessage, pageName);

  useEffect(() => {
    pushPageType(pageType, slug);
  }, [pageType, slug]);

  useEffect(() => {
    if (authResolved) {
      onPageView({ locale, path: asPath, pathname, slug, user });
    }
  }, [authResolved, pageType, slug, locale]);

  return (
    <SEO description={seoDescription} robots={handleContentfulRobots(contentfulPage)} title={seoTitle}>
      <ArticleSchemeScript date={getContentfulPageDate(contentfulPage)} seoTitle={seoTitle} />
      <main>
        <ContentfulRenderer contentfulPage={contentfulPage} />
      </main>
    </SEO>
  );
};

export default ContentfulContainer;

ContentfulContainer.getLayout = function getLayout(page: ReactNode) {
  return <PageLayout>{page}</PageLayout>;
};
