import useRouter from 'hooks/useRouter';
import Script from 'next/script';

interface ArticleSchemeScriptProps {
  date?: string;
  seoTitle?: string;
}

const ArticleSchemeScript = ({ date, seoTitle }: ArticleSchemeScriptProps) => {
  const { asPath } = useRouter();

  if (!date) return null;

  const structuredData = date && {
    '@context': 'https://schema.org',
    '@type': 'Article',
    dateModified: date,
    datePublished: date,
    headline: seoTitle,
  };
  return (
    <Script
      id={`ArticleSchemeScript-${asPath}`}
      dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      type="application/ld+json"
    />
  );
};
export default ArticleSchemeScript;
