import { ReactNode } from 'react';
import StaticPageNavigation from './StaticPageNavigation/StaticPageNavigation';

interface StaticPageProps {
  children: ReactNode;
}

const StaticPage = ({ children }: StaticPageProps) => (
  <div className="my-8 grid md:grid-cols-4 md:gap-12">
    <StaticPageNavigation />
    <div className="md:col-span-3">{children}</div>
  </div>
);

export default StaticPage;
