import { IPageContentFields } from 'types/ContentfulTypes';
import BirthdayConfettiVisual from './ConfiguredVisuals/BirthdayConfetti/Visual';
import ValentineHeartsVisual from './ConfiguredVisuals/ValentineHearts/Visual';
import WinterSnowflakesVisual from './ConfiguredVisuals/WinterSnowflakes/Visual';

type Props = {
  interactiveVisual: Required<IPageContentFields['interactiveVisual']>;
};

const ContentfulInteractiveVisual = ({ interactiveVisual }: Props) => {
  switch (interactiveVisual) {
    case 'Birthday':
      return <BirthdayConfettiVisual />;
    case 'Valentine':
      return <ValentineHeartsVisual />;
    case 'Winter':
      return <WinterSnowflakesVisual />;
    default:
      return null;
  }
};

export default ContentfulInteractiveVisual;
