import { FlyOver } from 'components/Layout';
import NavList from 'components/Nav/NavList/NavList';
import useRouter from 'hooks/useRouter';
import { useSelector } from 'hooks/useSelector';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getCMSPage } from 'redux/modules/cms';
import { Button } from 'ui/Button/Button';
import { Icon } from 'ui/Icon/Icon';
import { Link } from 'ui/Link/Link';
import { Text } from 'ui/Text/Text';
import { getCMSPageLinks } from 'utils/cmsUtil';
import { prefixWithLocale } from 'utils/urlUtil';
import NavListItem from '../../Nav/NavList/NavListItem/NavListItem';

const BASE_QUALIFIER = 'serviceoverview';
const SLOT_ID = 'serviceOverviewContentSlot';

const NavLinks = ({ onLinkClick }: { onLinkClick: () => void }) => {
  const { formatMessage } = useIntl();
  const { locale } = useRouter();
  const dispatch = useDispatch();

  const cmsPages = useSelector((state) => state.cms.pages);
  const overviewPage = cmsPages[`${BASE_QUALIFIER}-${locale}`];

  const links = getCMSPageLinks(overviewPage, SLOT_ID);

  useEffect(() => {
    if (links?.length === 0) {
      dispatch(getCMSPage(BASE_QUALIFIER, locale, true, false));
    }
  }, [links.length]);

  return (
    <NavList>
      <Text className="mb-4 border-b pb-2" tag="p" type="h3">
        {formatMessage({ id: 'cms_nav_header' })}
      </Text>

      {links?.map((link) => {
        if (!link) {
          return null;
        }
        const { externalUrl, localizedName, url } = link;
        const destination = url || externalUrl;
        if (!destination) {
          return null;
        }
        const destinationUrl = prefixWithLocale(destination, locale);

        return (
          <NavListItem key={`${url}-${localizedName}`}>
            <Link
              newTab={!!externalUrl}
              href={destinationUrl}
              className="flex items-center justify-between pb-2 pt-2"
              onClick={onLinkClick}
            >
              <Text>{localizedName}</Text>
              <Icon icon={{ name: 'angle-right', styling: 'fal' }} />
            </Link>
          </NavListItem>
        );
      })}
    </NavList>
  );
};

const StaticPageNavigation = () => {
  const { formatMessage } = useIntl();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <>
      <div className="md:hidden">
        <Button variant="ghost" icon={{ name: 'bars', styling: 'far' }} onClick={() => setShowMobileMenu(true)}>
          {formatMessage({ id: 'cms_nav_header' })}
        </Button>

        <FlyOver open={showMobileMenu} onOpenChange={setShowMobileMenu} title={formatMessage({ id: 'cms_nav_header' })}>
          <NavLinks onLinkClick={() => setShowMobileMenu(false)} />
        </FlyOver>
      </div>

      <div className="hidden md:block">
        <NavLinks onLinkClick={() => setShowMobileMenu(false)} />
      </div>
    </>
  );
};

export default StaticPageNavigation;
