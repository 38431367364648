import CMSEmptyPage from 'components/CMS/CMSPage/CMSEmptyPage/CMSEmptyPage';
import CMSSearchPage from 'components/CMS/CMSPage/CMSSearchPage/CMSSearchPage';
import { ANALYTICS_PAGE_TYPES } from 'constants/analytics';
import useAuthResolver from 'hooks/useAuthResolver';
import useRouter from 'hooks/useRouter';
import useTrackingEvents from 'hooks/useTrackingEvents';
import { ReactNode, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { CMSPageData, isCMSEmptyContentPage, isCMSSearchPage } from 'types/CMS';
import { mapRobotTag } from 'utils/cmsUtil';
import CMSSlot from '../components/CMS/CMSSlot/CMSSlot';
import PageLayout from '../components/PageLayout';
import SEO from '../components/Seo/SEO';
import StaticPage from '../components/StaticPage/StaticPage';
import { STATIC_CMS_PAGES } from '../constants/pageType';
import { localizedPathnames } from '../i18n/pathnames';
import { pushPageType } from '../utils/analyticsUtil';
import { getSeoData } from '../utils/seoUtil';
import { getPageNameFormUrl } from '../utils/urlUtil';

export type CMSPageProps = {
  cmsPage: CMSPageData;
  pageType: 'cmsPage' | 'cmsSearchPage';
  slug: string;
};

const CMSPageTemplate = ({ cmsPage }: { cmsPage: CMSPageData }) => {
  if (isCMSEmptyContentPage(cmsPage)) {
    return <CMSEmptyPage cmsPage={cmsPage} />;
  }
  if (isCMSSearchPage(cmsPage)) {
    return <CMSSearchPage cmsPage={cmsPage} />;
  }
};

const CMSContainer = ({ cmsPage, pageType, slug }: CMSPageProps) => {
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { asPath, locale, pathname } = router;
  const { authResolved, user } = useAuthResolver();
  const { onPageView } = useTrackingEvents();

  const pageName = getPageNameFormUrl(pathname, true);

  const { seoDescription, seoTitle } = getSeoData(cmsPage, formatMessage, pageName);
  const { robotTag } = cmsPage;
  const robots = mapRobotTag(robotTag);

  const isStaticCMSPage = STATIC_CMS_PAGES.some(
    (page) => localizedPathnames[page][locale] === `/${cmsPage?.qualifier}`,
  );

  useEffect(() => {
    pushPageType(pageType, slug);
  }, [pageType, slug]);

  useEffect(() => {
    if (authResolved && pageType !== ANALYTICS_PAGE_TYPES.CMS_SEARCH) {
      // Dont pass analyticsPageName to onPageView otherwise it will track as a CMSSearchPage
      onPageView({ locale, path: asPath, pathname, slug, user });
    }
  }, [authResolved, pageType, slug, locale]);

  return (
    <SEO description={seoDescription} title={seoTitle} robots={robots}>
      <main>
        {isStaticCMSPage ? (
          <StaticPage>
            <CMSSlot cmsPage={cmsPage} position={1} />
          </StaticPage>
        ) : (
          <CMSPageTemplate cmsPage={cmsPage} />
        )}
      </main>
    </SEO>
  );
};

export default CMSContainer;

CMSContainer.getLayout = function getLayout(page: ReactNode) {
  return <PageLayout>{page}</PageLayout>;
};
