import Image from 'components/Image/Image';
import Head from 'next/head';
import { useMemo } from 'react';
import { getRandomAnimationDelay, getRandomAnimationDuration } from 'utils/animation';
import { StyledAnimatedItem } from '../../AnimatedItem/AnimatedItem.styled';
import { COLORS, getRandomColor } from './ConfettiUtils';

const Logo = ({ color }: { color: string }) => {
  return (
    <Image
      src={`/images/birthday-65y/${color}.svg`}
      alt={`Krefel logo 65 years - ${color}`}
      width={50}
      height={50}
      className={`logo logo-${color}`}
    />
  );
};

const AMOUNT_OF_LOGOS = 8;

const Logos = () => {
  const randomLogos = useMemo(
    () =>
      Array.from(Array(AMOUNT_OF_LOGOS).keys()).map((index) => (
        <StyledAnimatedItem
          key={`logo-${index}`}
          animationDelay={getRandomAnimationDelay()}
          animationDuration={getRandomAnimationDuration()}
          offset={(index * 100) / AMOUNT_OF_LOGOS}
          size={50}
        >
          <Logo color={getRandomColor(index)} />
        </StyledAnimatedItem>
      )),
    []
  );
  return (
    <>
      <Head>
        {COLORS.map((color) => (
          <link
            key={`preload-logo-${color}`}
            rel="preload"
            href={`/images/birthday-65y/${color}.svg`}
            type="image/svg+xml"
          />
        ))}
      </Head>
      {randomLogos}
    </>
  );
};

export default Logos;
