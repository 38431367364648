import { FormatMessage } from 'types/I18n';
import { createTitle } from './textUtil';

// Custom type as this is used by multiple different page types
// FIXME: Review if this is still valid as props like "qualifier", "titleTag" and "metaDescription" don't seem to exist anymore
type Page = {
  description?: string;
  metaDescription?: string;
  qualifier?: string;
  title?: string;
  titleTag?: string;
};

export const getSeoData = (
  page: Page,
  formatMessage: FormatMessage,
  backupTitle?: string
): { seoDescription: string; seoTitle?: string } => {
  if (page) {
    const getTitle = () => {
      const { qualifier, title, titleTag } = page;

      if (titleTag || title) {
        return titleTag || title; // Custom title values
      }

      return formatMessage(
        {
          id: 'seo_content_page_title',
        },
        {
          value: createTitle(qualifier || backupTitle || ''),
        }
      );
    };

    return {
      seoDescription:
        page.metaDescription ||
        page.description ||
        formatMessage({
          id: 'seo_home_page_meta_description',
        }),
      seoTitle: getTitle(),
    };
  }

  return { seoDescription: '', seoTitle: '' };
};
