import InteractiveVisual from '../../InteractiveVisual/InteractiveVisual';
import SnowFlakes from './Snowflakes';

const WinterSnowflakesVisual = () => (
  <InteractiveVisual>
    <SnowFlakes />
  </InteractiveVisual>
);

export default WinterSnowflakesVisual;
